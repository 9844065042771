import React from 'react';

import { useTranslation } from 'next-i18next';

import Card from '@/components/BasicCard';
import { BodyText01, Heading04 } from '@/components/BasicText';
import * as Logo from '@/components/Logo';

const NotAvailable = () => {
  const { t } = useTranslation();
  return (
    <Card maxWidth="464px" data-testid="sos-customization__download__not-available" style={{ direction: 'rtl' }}>
      <Logo.Splashtop />
      <Heading04 py="48px">{t('sos-package:download.notAvailable.title')}</Heading04>
      <BodyText01 pb="1.5em">{t('sos-package:download.notAvailable.line1')}</BodyText01>
      <BodyText01 pb="1em">{t('sos-package:download.notAvailable.line2')}</BodyText01>
    </Card>
  );
};

export default NotAvailable;
