import type { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';

import { Global, css } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import colors from '@/design/theme/colors';
import { PublicLayout } from '@/layouts/public';
import Download from '@/modules/Customization/Download';
import DownloadRTL from '@/modules/Customization/Download/RTL';
import getLocale from '@/utils/getLocale';

const DownloadPage = () => {
  const router = useRouter();
  const packageCode = router.query.code as string;

  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'he';

  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${colors.blue[10]};
          }
        `}
      />
      <PublicLayout>{isRTL ? <DownloadRTL packageCode={packageCode} /> : <Download packageCode={packageCode} />}</PublicLayout>
    </>
  );
};

DownloadPage.displayName = 'DownloadPage';
DownloadPage.skipAuth = true;

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocale(req.headers['accept-language']), ['common', 'sos-package'])),
    },
  };
};

export default DownloadPage;
