
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/sos/packages/download/[code]",
      function () {
        return require("private-next-pages/sos/packages/download/[code].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/sos/packages/download/[code]"])
      });
    }
  