import React, { useCallback, useEffect } from 'react';

import getOS from '@/utils/getOS';
import { getRegion } from '@/utils/region';

import DownloadCard from './DownloadCard';
import NotAvailable from './NotAvailable';
import { downloadModel, useDownloadMachine } from './machine';
import type { Category } from './types';

type DownloadProps = {
  packageCode: string;
};

const DownloadPage = ({ packageCode }: DownloadProps) => {
  const [state, send] = useDownloadMachine(packageCode);

  const { downloadLink } = state.context;

  const handleDownload = useCallback(
    (category: Category) => () => {
      send(downloadModel.events.DOWNLOAD(category));
    },
    [send],
  );

  useEffect(() => {
    const os = getOS();
    if (os === 'Android' || os === 'iOS') {
      // If is mobile, redirect to sos download page
      const url = getRegion() === 'eu' ? 'https://sos.splashtop.eu' : 'https://sos.splashtop.com';
      location.href = url;
      return;
    }

    if (os === 'Mac') {
      handleDownload('mac')();
    } else {
      handleDownload('win')();
    }
  }, [handleDownload]);

  if (state.matches('blocking')) {
    return <NotAvailable />;
  }

  return (
    <>
      <DownloadCard onWindowsDownload={handleDownload('win')} onMacDownload={handleDownload('mac')} />
      {downloadLink && <iframe frameBorder="0" height="0" id="download-iframe" width="0" src={downloadLink}></iframe>}
    </>
  );
};

export default DownloadPage;
